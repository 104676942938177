//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
:root {
  --font-family: "Poppins";
  --arabic-font-family: "Tajawal";
  --danger-color: #EF4444;
  --success-color: #22C55E;
  --warning-color: #F59E0B;
}

.p-table-column-amount > div {
  float:right;
}